import { CheckupEntity } from "@network-module/entities/CheckupEntity";
import {
  GetPain,
  PhaseAnswer,
  ShortCutAnswer,
} from "@network-module/entities/CheckupEntityNew";
import { useQuery } from "@tanstack/react-query";
import { CheckupRepository } from "../repository/CheckupRepository";

export class CheckupService {
  repo: CheckupRepository;
  constructor(repository: CheckupRepository) {
    this.repo = repository;
  }

  start(checkupId: number) {
    return this.repo.start(checkupId).then((data) => {
      return data as CheckupEntity;
    });
  }

  kioskForceStopConfirm(kioskId: string, checkUpId: Number) {
    return this.repo.kioskForceStopConfirm(kioskId, checkUpId);
  }

  kioskAllocate(checkUpId: Number) {
    return this.repo.kioskAllocate(checkUpId).then((data) => data as number);
  }

  submitOne(phase1Data: any): Promise<any> {
    return this.repo.submitOne(phase1Data);
  }

  submitTwo(phase2Data: any): Promise<any> {
    return this.repo.submitTwo(phase2Data);
  }

  phaseCheck(data: PhaseAnswer): Promise<any> {
    return this.repo.phaseCheck(data);
  }

  checkupStartOrEnd(checkupId: number) {
    this.repo.checkupStartOrEnd(checkupId);
  }

  getShortCut(departmentId: number | string) {
    return useQuery({
      queryKey: ["getShortCut"],
      initialData: [],
      queryFn: async ({ signal }) => {
        return this.repo.getShortCut(departmentId);
      },
    });
  }

  saveAsk(shortCutAnswer: ShortCutAnswer): Promise<any> {
    return this.repo.saveAsk(shortCutAnswer);
  }

  getSickWhere(departmentId: number) {
    return useQuery({
      queryKey: ["getSickWhere"],
      initialData: {} as GetPain,
      queryFn: async ({ signal }) => {
        return this.repo.getSickWhere(departmentId, signal);
      },
    });
  }

  getSickPeriod() {
    return useQuery({
      queryKey: ["getSickPeriod"],
      queryFn: async ({ signal }) => {
        return this.repo.getSickPeriod();
      },
    });
  }

  getCheckupScreen(departmentId: number) {
    return useQuery({
      queryKey: ["getCheckupScreen"],
      queryFn: async ({ signal }) => {
        return this.repo.getCheckupScreen(departmentId, signal);
      },
    });
  }

  getElapse(departmentId: number) {
    return useQuery({
      queryKey: ["getElapse"],
      queryFn: async ({ signal }) => {
        return this.repo.getElapse(departmentId, signal);
      },
    });
  }

  getAdditionalPain(departmentId: number) {
    return useQuery({
      queryKey: ["getAdditionalPain"],
      queryFn: async ({ signal }) => {
        return this.repo.getAdditionalPain(departmentId, signal);
      },
    });
  }

  saveReuse(reuseData: any): Promise<any> {
    return this.repo.saveReuse(reuseData);
  }

  kioskResetForce(checkUpId: Number) {
    return this.repo.kioskReset(checkUpId).then((data) => data);
  }
}
