import { QueryClient } from "@tanstack/react-query";
import { AuthRepository } from "./repository/AuthRepository";
import { CheckupRepository } from "./repository/CheckupRepository";
import { HospitalRepository } from "./repository/HospitalRepository";
import { TermRepository } from "./repository/TermRepository";
import { AuthService } from "./services/AuthService";
import { CheckupService } from "./services/CheckupService";
import { HospitalService } from "./services/HospitalService";
import { TermService } from "./services/TermService";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

export class NetworkManager {
  static checkupService: CheckupService = new CheckupService(
    new CheckupRepository()
  );

  static authService: AuthService = new AuthService(new AuthRepository());
  static termService: TermService = new TermService(new TermRepository());
  static hospitalService: HospitalService = new HospitalService(
    new HospitalRepository()
  );
}
