import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "regenerator-runtime";
import "regenerator-runtime/runtime";

const ReceptionIndex = lazy(() => import("@/pages/reception/ReceptionIndex"));
const ScreenIndex = lazy(() => import("@/pages/screening/ScreenIndex"));

const ReceptionComplete = lazy(
  () => import("@/pages/reception/ReceptionComplete")
);
const ReceptionPage = lazy(() => import("@/pages/reception/ReceptionPage"));
const ScreenPage = lazy(() => import("@/pages/screening/ScreenPage"));

const router = createBrowserRouter([
  {
    path: "/reception",
    children: [
      {
        path: "/reception",
        index: true,
        element: (
          <Suspense fallback={<div></div>}>
            <ReceptionIndex />
          </Suspense>
        ),
      },
      {
        path: "/reception/main",
        element: (
          <Suspense fallback={<div></div>}>
            <ReceptionPage />
          </Suspense>
        ),
      },
      {
        path: "/reception/complete",
        element: (
          <Suspense fallback={<div></div>}>
            <ReceptionComplete />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/checkup",
    children: [
      {
        path: "/checkup",
        element: (
          <Suspense fallback={<div></div>}>
            <ScreenIndex />
          </Suspense>
        ),
      },
      {
        path: "/checkup/main",
        element: (
          <Suspense fallback={<div></div>}>
            <ScreenPage />
          </Suspense>
        ),
      },
    ],
  },
]);

export const Router = () => <RouterProvider router={router} />;
