const emptyVdBridge = {
  getDeivceUniqueKey: (): Promise<string> => {
    return new Promise((resolve) =>
      resolve(localStorage.getItem("deviceId") ?? deviceUniqueKey)
    );
    // return new Promise((resolve) => resolve("zxasdfascvcv"));
  },
  //   requestClose: () => {
  //     console.log("excute request-close");
  //   },
  setStorageData: (key: string, value: string) => {},

  getStorageData: () => {
    return new Promise((resolve) => resolve(""));
  },

  getApiUrl: (): string => {
    return "a";
    // return new Promise((resolve) => resolve(""));
  },
  //   showCloseModal: () => {
  //     console.log(`excute showCloseModal`);
  //   },

  getMSApi: () => {},
};

let deviceUniqueKey = "Ulg6YXIPNKCamn9IWU0dQg==";
let apiUrl = "";
let hospital = "";
let msInfo = {
  region: "",
  key: "",
};

export default class AppSetting {
  static async init() {
    const vdBridge = this.getVdBridge();

    try {
      apiUrl = await vdBridge.getApiUrl();
    } catch {
      console.error("testIP를 가져올 수 없음");
    }

    try {
      deviceUniqueKey = await vdBridge.getDeivceUniqueKey();
    } catch (e) {
      console.error("deviceUniqueKey를 가져올 수 없음");
    }

    try {
      // hospital = await vdBridge.getStorageData("hospital");
    } catch (e) {
      console.error("hospital 가져올 수 없음");
    }

    try {
      // msInfo = await vdBridge.getMSApi();
    } catch (e) {
      console.error("setting 가져올 수 없음");
    }
  }

  static isElectronApp() {
    return undefined;
  }
  static getVdBridge() {
    return emptyVdBridge;
  }
  static getApiUrl() {
    return apiUrl;
  }
  static getDeviceUniqueKey() {
    return localStorage.getItem("deviceId") ?? deviceUniqueKey;
    // return deviceUniqueKey;
  }

  static getHospital() {
    return hospital;
  }

  static getMSApi() {
    return msInfo;
  }
}
