import React, { createContext, useContext, ReactNode } from "react";
import WebSocketService from "@network-module/services/WebSocketService";

interface StompContextProps {
  websocket: WebSocketService;
}

interface StompProviderProps {
  children: ReactNode;
}

const StompContext = createContext<StompContextProps | undefined>(undefined);

export const StompProvider: React.FC<StompProviderProps> = ({ children }) => {
  const websocket = WebSocketService.getInstance();

  return (
    <StompContext.Provider value={{ websocket }}>
      {children}
    </StompContext.Provider>
  );
};

export const useStomp = (): StompContextProps => {
  const context = useContext(StompContext);

  if (!context) {
    throw new Error("useStomp must be used within a StompProvider");
  }

  return context;
};
