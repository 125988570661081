import { Client } from "@stomp/stompjs";
type Subscription = {
  destination: string;
  callback: (message: any) => any;
};
export default class WebSocketService {
  private subscriptions: Map<string, Subscription> = new Map();
  // url = AppSetting.getApiUrl();
  // private subscriptions: Array<{
  //   id?: string;
  //   destination: string;
  //   callback: (message: any) => any;
  // }> = [];
  private static instance: WebSocketService | null = null;
  private socket: Client;
  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }
  private constructor() {
    this.socket = new Client({
      brokerURL: import.meta.env.VITE_WEBSOCKET_URL,
      onConnect: (frame) => {
        console.log("Connected: " + frame);
        this.subscriptions.forEach((value, key) => {
          this.subscribe(value.destination, value.callback, key);
        });
      },
      onWebSocketClose: () => {
        this.socket.deactivate();
        this.socket.activate();
      },
      heartbeatIncoming: 5000,
      heartbeatOutgoing: 5000,
      connectionTimeout: 1200000,
    });
    this.socket.activate();
  }
  isConnected() {
    return this.socket.connected;
  }
  deactivate() {
    this.socket.deactivate();
  }
  onMessage(message: string) {
    console.log(message);
  }
  send(destination: string, body: Record<string, any>) {
    this.socket.publish({ destination, body: JSON.stringify(body) });
  }
  subscribe(
    destination: string,
    callback: (message: any) => any,
    id?: string,
    onSubscribe?: () => void
  ) {
    if (this.isConnected()) {
      this.socket.subscribe(destination, callback, id ? { id } : undefined);
      onSubscribe?.();
    }
    this.subscriptions.set(id, { destination, callback });
  }
  unsubscribe(destination: string) {
    if (this.isConnected()) {
      this.socket.unsubscribe(destination);
    }
  }
}
