import { QueryClientProvider } from "@tanstack/react-query";
import "@utils/i18n/i18n";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import "regenerator-runtime";
import "regenerator-runtime/runtime";
import { Router } from "./common/router";
import StorageManager from "./common/services/StorageManager";
import KioskRegister from "./components/kiosk/KioskRegister";
import { useAuth } from "./context/AuthStore";
import "./index.css";
import { StompProvider, useStomp } from "./network-module/context/StompContext";
import { queryClient } from "./network-module/NetworkManager";

const RootElement = () => {
  const { isAuth } = useAuth();
  const { websocket } = useStomp();

  useEffect(() => {
    let kioskId = StorageManager.getDataNow("kioskId");

    websocket.subscribe(
      `/kiosk/${kioskId}/reset`,
      (message) => {
        location.href = "/checkup";
      },
      "reset"
    );
  }, []);

  if (isAuth) {
    return <Router />;
  }

  return <KioskRegister />;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <StompProvider>
        <RootElement />
      </StompProvider>
    </QueryClientProvider>
  </RecoilRoot>
);
