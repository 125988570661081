import { DoctorWait } from "../entities/DoctorEntity";
import {
  CheckReceiptEntity,
  CheckReciptResponseEntity,
} from "../entities/HospitalEntity";
import { BaseRepository } from "./BaseRepository";

export class HospitalRepository extends BaseRepository {
  checkReceiptType(
    data: CheckReceiptEntity
  ): Promise<CheckReciptResponseEntity> {
    return this.api.post(`/hospital/kiosk/receipt/type`, data);
  }

  getDoctorListWithWait(): Promise<DoctorWait[]> {
    return this.api.get(`/hospital/doctors/wait`);
  }

  //당일 접수
  kioskCheckIn(data: any): Promise<any> {
    return this.api.post(`/hospital/kiosk/check-in`, data);
  }

  //재방문
  kioskReceiptWithHp(data: any): Promise<any> {
    return this.api.post(`/hospital/kiosk/receipt/hp`, data);
  }

  //방문
  kioskReceiptWithNoHp(data: any): Promise<any> {
    return this.api.post(`/hospital/kiosk/receipt/no-hp`, data);
  }

  //30일 재방문 조회
  getThirtyDays(hpId: number | string): Promise<any> {
    return this.api.get(`/hospital/pre-clinic/thirty-days?hpId=${hpId}`);
  }
}
