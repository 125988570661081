import { useQuery } from "@tanstack/react-query";
import {
  CheckReceiptEntity,
  CheckReciptResponseEntity,
} from "../entities/HospitalEntity";
import { HospitalRepository } from "../repository/HospitalRepository";

export class HospitalService {
  repo: HospitalRepository;
  constructor(repo: HospitalRepository) {
    this.repo = repo;
  }

  checkReceiptType(
    data: CheckReceiptEntity
  ): Promise<CheckReciptResponseEntity> {
    return this.repo.checkReceiptType(data);
  }

  getDoctorListWithWait() {
    return useQuery({
      queryKey: ["getDoctorListWithWait"],
      initialData: [],
      queryFn: async ({ signal }) => {
        return this.repo.getDoctorListWithWait();
      },
    });
  }

  //당일 접수
  kioskCheckIn(data: any): Promise<any> {
    return this.repo.kioskCheckIn(data);
  }

  //재방문
  kioskReceiptWithHp(data: any): Promise<any> {
    return this.repo.kioskReceiptWithHp(data);
  }

  //방문
  kioskReceiptWithNoHp(data: any): Promise<any> {
    return this.repo.kioskReceiptWithNoHp(data);
  }

  //30일 재방문 조회
  getThirtyDays(hpId: number | string) {
    return useQuery({
      queryKey: ["getThirtyDays"],
      initialData: "zero",
      queryFn: async ({ signal }) => {
        return this.repo.getThirtyDays(hpId);
      },
    });
  }
}
